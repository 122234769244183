import getConfig from 'next/config';
import { SelectedTile, TileType } from '@components/topNavMenu/gml/GMLQueueModal';

const { publicRuntimeConfig } = getConfig();

/**
 * Navigation action builder provides links and navigation handlers for Resdesk navigation as 3-d party app for RDK2.
 * For any navigations that needs to be handled inside RDK2 use Next.js router.
 */
export class NavigationActionBuilder {
  private static resDeskUrl: string = publicRuntimeConfig.RESDESK_URL;

  private static isDevLogin = publicRuntimeConfig.DEV_LOGIN === 'true';

  /**
   * Returns link to Resdesk GML queue page.
   * <p>
   * The navigation destination is different for different type of selected tiles, as we can get ORG and HOTEL in
   * the modal we need to navigate them separately where HOTEL needs to be presented on Resdesk side and
   * ORG is directed always to default selection for user
   *
   * @param selectedTile with identifier of participant and a type of selected tile
   */
  public static getGMLQueueContinueLink(selectedTile: SelectedTile): string {
    switch (selectedTile.type) {
      case TileType.HOTEL: {
        return `${this.resDeskUrl}/UXGroupmasterLinkQueue.action?hotelID=${selectedTile.id}`;
      }
      case TileType.ORG: {
        return `${this.resDeskUrl}/UXGroupmasterLinkQueue.action`;
      }
      default: {
        return `${this.resDeskUrl}/UXGroupmasterLinkQueue.action`;
      }
    }
  }

  /**
   * Returns link to home page.
   */
  public static getSwitchToHomePageBannerLink(): string {
    return this.getResdeskHomePageLink();
  }

  /**
   * Returns link to Resdesk home page.
   */
  public static getResdeskHomePageLink(): string {
    return `${this.resDeskUrl}/PKXEntry.action?nxtpage=home`;
  }

  /**
   * Returns link to Resdesk home page.
   */
  public static getResdeskLogInPageLink(): string {
    return this.isDevLogin ? this.resDeskUrl : `${this.resDeskUrl}/Login.action?logout=true&mem=1`;
  }

  /**
   * Returns Resdesk RezHub Delivery page with home preselect.
   */
  public static getRezHubDeliveryLink(): string {
    return `${this.resDeskUrl}/ResQGLReportEntryAction.do?mode=resqsummary&fromLink=true`;
  }
}
