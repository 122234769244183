/** Represents MetaData that created in Passkey Auth service. */
import { NavigationHandler } from '@schema/types';

export interface MetaData {
  UserStub: string;
  accountMappingId: string;
  adminUser: boolean;
  apiUser: boolean;
  creditCardInfoViewable: boolean;
  customerFlags: number;
  disabled: number; // represents boolean disabled flag
  emailAddress: string;
  firstName: string;
  hasSisterHotels: boolean;
  invalidLoginCount: number;
  lastName: string;
  objectTypeId: number;
  participantId: number;
  participantType: ParticipantType;
  reportingLevel: ReportingLevel;
  testUser: boolean;
  userId: number;
  userStatus: number;
  userTypeId: UserTypeId;
  username: string;
  isCSO: number; // is corporate standards organization
}

/** User reporting level can be configured in Resdesk user profile page or PERM.REPORTINGLEVEL. */
export enum ReportingLevel {
  LIMITED_REPORTING_USER = '50',
  BASIC_REPORTING_USER = '100',
  ADVANCED_REPORTING_USER = '200',
  POWER_REPORTING_USER = '300',
  ADMINISTRATOR_REPORTING_USER = '400'
}

/** Participant type names. */
export enum ParticipantType {
  PASSKEY = 'Passkey',
  ORG = 'Organizer',
  HOTEL = 'Hotel'
}

export enum UserTypeId {
  PASSKEY = 100,
  SR_ORG = 101,
  ORG = 102,
  HOTEL = 103,
  WEB_USER = 104,
  GENERIC_CC = 105,
  HOTEL_CC = 106,
  RLM_MANAGER = 107,
  MARKETING = 108
}

export enum ObjectTypeId {
  ORGANIZER = 100,
  HOTEL = 101,
  VENDOR = 102,
  SPONSOR = 103,
  EVENT = 104,
  PASSKEY = 105
}

export enum MetadataFields {
  OBJECT_TYPE_ID = 'objectTypeId',
  USER_TYPE_ID = 'userTypeId',
  USER_ID = 'userId',
  USER_NAME = 'username',
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  PARTICIPANT_ID = 'participantId',
  PARTICIPANT_TYPE = 'participantType',
  HAS_SIS_HOTELS = 'hasSisterHotels',
  CUSTOMER_FLAGS = 'customerFlags',
  ACCOUNT_MAPPING_ID = 'accountMappingId',
  USER_EMAIL = 'userEmail',
  ADMIN_USER = 'adminUser',
  IS_CSO = 'isCSO' // is corporate standards organization
}

export enum ModulePrivilege {
  ARI = 'ARI',
  ATTENDEE_WEBSITE_DASHBOARD = 'ATTENDEEWEBSITEDASHBOARD',
  PK_INTERFACE_SETTINGS = 'PKInterfaceSettings',
  ADMIN_CREATOR = 'ADMIN CREATOR',
  ADVANCED_REPORTING = 'ADVANCEDREPORTING',
  AWS_GOOGLE_TRANSLATE = 'AWSGOOGLETRANSLATE',
  BLOCK_REQ_ALERTS_WIDGET = 'BLOCKREQALERTSWIDGET',
  COMMERCE = 'COMMERCE',
  COOLBRIX = 'COOLBRIX',
  CORPORATE_STANDARDS = 'CORPORATESTANDARDS',
  ECOMMERCE = 'ECOMMERCE',
  ENROLLMENT_LIBRARY = 'ENROLLMENTLIBRARY',
  EVENT = 'EVENT',
  EVENT_ALERTS_WIDGET = 'EVENTALERTSWIDGET',
  EVENT_FREQ_ASKS_WIDGET = 'EVENTFREQASKSWIDGET',
  EVENT_LIST_WIDGET = 'EVENTLISTWIDGET',
  EXTERNAL_REGISTRATION = 'EXTERNAL_REGISTRATION',
  FREQUENT_TASKS_WIDGET = 'FREQUENTTASKSWIDGET',
  GROUP_CAMPAIGN = 'GROUPCAMPAIGN',
  GROUP_LINK = 'GROUPLINK',
  GROUP_LINK_TRANSFER = 'GROUPLINKTRANSFER',
  GROUP_MASTER_LINK = 'GROUPMASTERLINK',
  HOTEL_DASHBOARD = 'HOTELDASHBOARD',
  HOME_TAB = 'HomeTab',
  INVENTORY = 'INVENTORY',
  J_REPORT_LIVE = 'JREPORTLIVE',
  LEARNING_CENTER_WIDGET = 'LEARNINGCENTERWIDGET',
  LEGACY_INVENTORY = 'LEGACYINVENTORY',
  MANUAL_REFUND = 'MANUALREFUND',
  MMM = 'MMM',
  NON_LATIN_CHAR_SETS = 'NONLATINCHARSETS',
  ORGANIZER_DASHBOARD = 'ORAGNIZERDASHBOARD',
  PERSONAL_MERCHANT_ACCOUNT = 'PERSONALMERCHANTACCOUNT',
  PLANNER_COMMUNICATION = 'PLANNERCOMMUNCATION',
  POWER_REPORTING = 'POWERREPORTING',
  PROFILE = 'PROFILE',
  QUICKLIST = 'QUICKLIST',
  REPORTS = 'REPORTS',
  REPORTS_SCHEDULING = 'REPORTSSCHEDULING',
  RESERVATION = 'RESERVATION',
  REZ_HUB = 'REZHUB',
  REZ_HUB_ROOM_LISTS = 'REZHUBROOMLISTS',
  ROOM_LIST_MACRO = 'ROOMLISTMACRO',
  SBD_RLM_MAPPING = 'SBDRLMMAPPING',
  SMART_EMAIL = 'SMARTEMAIL',
  TRAVEL_INFO = 'TRAVELINFO',
  USER_MODULE = 'USERMODULE',
  WAIT_LIST = 'WAITLIST',
  WEBSITE_LIBRARY = 'WEBSITELIBRARY',
  OLD_GL_REPORTS = 'OldGLReports'
}

export enum PermissionType {
  PERMISSION = 'permission',
  MODULE_PRIVILEGE = 'modulePrivilege'
}

export enum AccessType {
  READ = 'READ',
  ADD = 'ADD',
  MODIFY = 'MODIFY',
  DELETE = 'DELETE'
}

export enum Permissions {
  GL_4_0 = 'GL4.0',
  GROUP_MASTER_LINK = 'GroupmasterLink',
  ADMIN_PAGES = 'AdminPages',
  ROOM_LIST_MANAGER = 'RoomListManager'
}

export interface MenuItem {
  id: string;
  title: string;
  href: string;
  url: string;
  modulePrivilege: ModulePrivilege[];
  permission?: Permissions;
  openInNewWindow?: boolean;
  windowFeature?: string;
  additionalConditions: boolean | NavigationHandler;
}

export interface TopNavItem {
  url: string;
  title: string;
}
