import { ParameterizedLocalStorage } from './ParameterizedLocalStorage';
import { ParameterizedSessionStorage } from './ParameterizedSessionStorage';
import { StorageInitParams } from './types';
import { SharedStorage } from './SharedStorage';

/**
 * Returns existed or new Session storage that inherits type from SharedStorage.
 *
 * @param params optional object with userId
 */
export const getLocalStorage = (params?: StorageInitParams): SharedStorage => {
  return ParameterizedLocalStorage.getLocalStorage(params);
};

/**
 * Returns existed or new Session storage that inherits type from SharedStorage.
 *
 * @param params optional object with userId
 */
export const getSessionStorage = (params?: StorageInitParams): SharedStorage => {
  return ParameterizedSessionStorage.getSessionStorage(params);
};
