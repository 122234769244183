import { fetchAndRetryIfServerBusy, RequestBuilder } from '@cvent/nucleus-networking';
import { setItem } from '@cvent/nextjs/utils/storage';
import { MetaData, MetadataFields } from '@components/types';
import { getLocalStorage, getSessionStorage } from '@utils/storage/ParameterizedStorage';
import getConfig from 'next/config';
import { NavigationActionBuilder } from '@components/topNavMenu/NavigationActionBuilder';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

/**
 * Logout function is redirecting the user to Resdesk logout page,
 * which is expiring the Resdesk token.
 */
const logOut = (): void => {
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();
  if (isBrowser) {
    window.location.href = NavigationActionBuilder.getResdeskLogInPageLink();
    getSessionStorage().clear();
    getLocalStorage().clear();
  }
};

const extendUserSession = async (rdToken?): Promise<number> => {
  const extendSession = new RequestBuilder({ url: `${serverRuntimeConfig.PASSKEY_AUTH_URL}/sessionHandle/refresh` })
    .header('rdSessionId', rdToken)
    .auth('API_KEY ' + serverRuntimeConfig.API_KEY)
    .put()
    .build();

  const response: Response = await fetchAndRetryIfServerBusy(extendSession);

  return response?.status;
};

/**
 * This code pertains to the use of the health check endpoint in Resdesk for keep alive requests from Lighthouse,
 * which is necessary to maintain the ALB Stick Session Cookie.
 * The function is triggered based on the KEEP_ALIVE_INTERVAL set in the session handler.
 * We always return void regardless of the status of the keep alive call.
 * This is done to prevent the keep alive calls from failing lighthouse.
 */
const makeRefreshCsrfRequest = async () => {
  if (publicRuntimeConfig.DEV_LOGIN !== 'true') {
    const resdeskKeepAliveRequest = new RequestBuilder({
      url: `${publicRuntimeConfig.KEEP_ALIVE_ENDPOINT}/ok`,
      credentials: 'include'
    })
      .get()
      .build();
    await fetchAndRetryIfServerBusy(resdeskKeepAliveRequest);
  }
};

/**
 * Initializes storage with CSRF Token and some metadata.
 *
 * @param metadata
 */
const initializeStorage = (metadata?: MetaData) => {
  getLocalStorage({ userId: metadata?.userId?.toString() });
  getSessionStorage({ userId: metadata?.userId?.toString() });

  setItem(MetadataFields.USER_ID, metadata?.userId?.toString());
  setItem(MetadataFields.USER_NAME, metadata?.username);
  setItem(MetadataFields.LAST_NAME, metadata?.lastName);
  setItem(MetadataFields.FIRST_NAME, metadata?.firstName);
  setItem(MetadataFields.CUSTOMER_FLAGS, metadata?.customerFlags?.toString());
  setItem(MetadataFields.USER_TYPE_ID, metadata?.userTypeId?.toString());
  setItem(MetadataFields.OBJECT_TYPE_ID, metadata?.objectTypeId?.toString());
  setItem(MetadataFields.PARTICIPANT_ID, metadata?.participantId?.toString());
  setItem(MetadataFields.PARTICIPANT_TYPE, metadata?.participantType);
  setItem(MetadataFields.HAS_SIS_HOTELS, metadata?.hasSisterHotels?.toString());
  setItem(MetadataFields.USER_EMAIL, metadata?.emailAddress);
  setItem(MetadataFields.ADMIN_USER, metadata?.adminUser?.toString());
  // is corporate standards organization
  setItem(MetadataFields.IS_CSO, metadata?.isCSO?.toString());
};

export { logOut, extendUserSession, makeRefreshCsrfRequest, initializeStorage };
