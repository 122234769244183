import React from 'react';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { GMLEnabledOrg, SelectedTile, TileType } from '@components/topNavMenu/gml/GMLQueueModal';
import { useTranslate } from 'nucleus-text';
import { Tile, TileBody, TileFooter } from '@cvent/carina/components/Tile';

/**
 * GML Queue org card props with only one org provided.
 */
interface GMLQueueOrgProp {
  isSelected: boolean;
  setSelectedTile(prevState: SelectedTile): void;
  org: GMLEnabledOrg;
  isPasskeyUser?: boolean;
}

/**
 * GML Queue org card.
 * <p/>
 * We can't reuse Carina Card component because of strict rule of direction, text alignment and border handling.
 * The carina Card component doesn't allow to change it, so GML Queue modal implements Carina Tile.
 * The GML Queue org card has an action to trigger on user click event and renders and change status to selected.
 * On top of action handling card render Org name and icon on select.
 *
 * @param props includes selected state on click handler and org data for rendering
 */
export const GMLQueueOrg = (props: GMLQueueOrgProp): JSX.Element => {
  const { font } = useTheme();
  const { translate } = useTranslate();

  const buildGMLTitle = (): string => {
    if (!props.org.name) {
      return props.isPasskeyUser
        ? translate('Lighthouse_GML_Queue_Passkey_User_Organization', { organizationId: props.org.id })
        : translate('Lighthouse_GML_Queue_Org_User_Organization');
    }
    return props.org.name;
  };

  // Carina Tile allows to unselect tile, this leads to an initial org card state where no org is selected.
  const handleClick = (orgId: number): void =>
    props.isSelected ? props.setSelectedTile(null) : props.setSelectedTile({ type: TileType.ORG, id: orgId });

  return (
    <div {...injectTestId(`gml-queue-modal-org-list-item-container-${props.org.id}`)} css={{ marginBottom: '8px' }}>
      <Tile
        clickable
        onClick={() => handleClick(props.org.id)}
        padding={16}
        selectable
        isSelected={props.isSelected}
        testID={`gml-queue-modal-org-list-item-group-${props.org.id}`}
      >
        <TileBody
          css={{
            textAlign: 'left',
            fontSize: font.base.size.l,
            fontWeight: font.base.weight.regular,
            fontFamily: font.base.family,
            color: font.color.hard,
            lineHeight: font.lineHeight.base,
            minHeight: 0
          }}
          {...injectTestId(`gml-queue-modal-org-list-item-description-${props.org.id}`)}
        >
          <h3
            {...injectTestId(`gml-queue-modal-org-list-item-name-${props.org.id}`)}
            css={{ margin: 0, padding: 0, fontSize: font.base.size.m }}
          >
            {buildGMLTitle()}
          </h3>
        </TileBody>
        <TileFooter
          {...injectTestId(`gml-queue-modal-org-list-item-location-${props.org.id}`)}
          css={{ fontSize: '0.8rem', textAlign: 'left', margin: 0, padding: 6 }}
        ></TileFooter>
      </Tile>
    </div>
  );
};
