import { getItem, setItem } from '@cvent/nextjs/utils/storage';
import { MetadataFields } from '@components/types';
import { Logger } from '@cvent/nucleus-logging';
import { StoredItems } from './types';

const LOG = new Logger('SharedStorage');

/**
 * The abstract class that defines base functions to work with NX based library for local storage. In addition,
 * class defines user specific methods that can be implemented by particular concrete storage class. For example,
 * Local Storage and Session Storage have different implementation of storage, this shared storage will be returned
 * in Parameterized factory function.
 */
export abstract class SharedStorage {
  private readonly userId: string;

  protected constructor(userId: string) {
    this.userId = userId;
  }

  /**
   * Returns user ID that has been set up during concrete class initialization.
   */
  public getUserId(): string {
    return this.userId || getItem(MetadataFields.USER_ID);
  }

  /**
   * Returns saved value by provided key. In case of error on handling key, throws and swallows exception with
   * logging action. We swallow exception because we don't want to bubble up failure and each component that tries
   * to use user saved data has its own default selection that handle empty value.
   *
   * @param key string literal for value
   */
  public static getItem(key: string): string {
    try {
      return getItem(key);
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown in get ${key} item.`);
    }
  }

  /**
   * Saves key/value pair that provided in local storage.
   *
   * @param key string literal for key
   * @param value string literal for value
   */
  public static setItem(key: string, value: string): void {
    try {
      return setItem(key, value);
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality
      LOG.debug(`Error has been thrown in set ${key} item.`);
    }
  }

  /**
   * Returns item from defined storage by user specific identifier.
   *
   * @param key to fetch value
   */
  abstract getUserItem(key: StoredItems): string;

  /**
   * Sets item to defined storage by user specific identifier.
   *
   * @param key to set value
   * @param value for setting in defined storage
   */
  abstract setUserItem(key: StoredItems, value: string): void;

  /**
   * Clears storage.
   */
  abstract clear(): void;
}
