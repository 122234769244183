import React from 'react';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { GMLEnabledHotel, SelectedTile, TileType } from '@components/topNavMenu/gml/GMLQueueModal';
import { useTranslate } from 'nucleus-text';
import { Tile, TileBody, TileFooter } from '@cvent/carina/components/Tile';

/**
 * GML Queue hotel card props with only one hotel provided.
 */
interface GMLQueueHotelProp {
  isSelected: boolean;
  setSelectedTile(prevState: SelectedTile): void;
  hotel: GMLEnabledHotel;
}

/**
 * GML Queue hotel card.
 * <p/>
 * We can't reuse Carina Card component because of strict rule of direction, text alignment and border handling.
 * The carina Card component doesn't allow to change it, so GML Queue modal implements Carina Tile.
 * The GML Queue hotel card has an action to trigger on user click event and renders and change status to selected.
 * On top of action handling card render Hotel name, location and icon on select.
 *
 * @param props includes selected state on click handler and hotel data for rendering
 */
export const GMLQueueHotel = (props: GMLQueueHotelProp): JSX.Element => {
  const { font } = useTheme();
  const { translate } = useTranslate();

  // Hotel Card needs to recalculate location representation based on selected locale
  // because not all locales support the same location format.
  const buildHotelLocation = (): string =>
    translate('Lighthouse_Navigation_GMLQueue_Modal_Hotel_Item_Location_resx', {
      hotelCity: props.hotel.city,
      hotelState: props.hotel.state
    });

  // Carina Tile allows to unselect tile, this leads to a initial hotel card state where no hotel is selected.
  const handleClick = (hotelId: number): void =>
    props.isSelected ? props.setSelectedTile(null) : props.setSelectedTile({ type: TileType.HOTEL, id: hotelId });

  return (
    <div
      {...injectTestId(`gml-queue-modal-hotel-list-item-container-${props.hotel.hotelId}`)}
      css={{ marginBottom: '8px' }}
    >
      <Tile
        clickable
        onClick={() => handleClick(props.hotel.hotelId)}
        padding={16}
        selectable
        isSelected={props.isSelected}
        testID={`gml-queue-modal-hotel-list-item-group-${props.hotel.hotelId}`}
      >
        <TileBody
          css={{
            textAlign: 'left',
            fontSize: font.base.size.l,
            fontWeight: font.base.weight.regular,
            fontFamily: font.base.family,
            color: font.color.hard,
            lineHeight: font.lineHeight.base,
            minHeight: 0
          }}
          {...injectTestId(`gml-queue-modal-hotel-list-item-description-${props.hotel.hotelId}`)}
        >
          <h3
            {...injectTestId(`gml-queue-modal-hotel-list-item-name-${props.hotel.hotelId}`)}
            css={{ margin: 0, padding: 0, fontSize: font.base.size.m }}
          >
            {props.hotel.name}
          </h3>
        </TileBody>
        <TileFooter
          {...injectTestId(`gml-queue-modal-hotel-list-item-location-${props.hotel.hotelId}`)}
          css={{ fontSize: '0.8rem', textAlign: 'left', margin: 0, padding: 0 }}
        >
          <p
            css={{
              margin: 0,
              padding: 0,
              fontSize: font.base.size.xxs,
              fontWeight: font.base.weight.regular,
              fontFamily: font.base.family,
              color: font.color.soft,
              lineHeight: font.lineHeight.base
            }}
          >
            {buildHotelLocation()}
          </p>
        </TileFooter>
      </Tile>
    </div>
  );
};
