import { MetadataFields } from '@components/types';
import { Logger } from '@cvent/nucleus-logging';
import { SharedStorage } from './SharedStorage';
import { LocalItems, StorageInitParams } from './types';

const LOG = new Logger('LocalStorage');

/**
 * Custom handler of user related selection in browser local storage.
 * <p/>
 * ParameterizedLocalStorage has singleton factory method that checks for existed instance of class and returns
 * existed class instance with saved user ID, if not creates one with provided user ID or fetches it from local storage.
 * RDK2 has use cases where we need to set local storage items with unique key/value pair. For example,
 * if several users log in from the same browser we want to keep their saved preferences separately.
 * That is a main reason to use custom local storage. In case if we need to use any value from browser storage,
 * take a look at to methods in SharedStorage abstract class:
 * {@code SharedStorage.getItem(key: string) and SharedStorage.setItem(key: string, value: string) }
 */
export class ParameterizedLocalStorage extends SharedStorage {
  private static local: ParameterizedLocalStorage;

  private constructor(storageParams: StorageInitParams) {
    super(storageParams.userId);
  }

  /**
   * Factory static method that builds instance of ParameterizedSessionStorage class with default userID.
   *
   * @param storageParams with logged in userId
   */
  public static getLocalStorage(storageParams?: StorageInitParams): ParameterizedLocalStorage {
    if (!ParameterizedLocalStorage.local) {
      if (storageParams?.userId) {
        ParameterizedLocalStorage.local = new ParameterizedLocalStorage({ userId: storageParams?.userId });
      } else {
        LOG.debug('Set up default user id from existed local storage.');
        ParameterizedLocalStorage.local = new ParameterizedLocalStorage({
          userId: super.getItem(MetadataFields.USER_ID)
        });
      }
    }
    return ParameterizedLocalStorage.local;
  }

  /**
   * Returns item from local storage by user specific identifier.
   * <p/>
   * In order to return user specific setting we need to check if userID exists otherwise we can get exception
   * and skip an unexpected key/value saved from one page to another, even thought we shouldn't try to fetch user
   * specific settings for generic fetch but in case one-page sets item without any identifier and second page needs
   * to fetch it we still want to support flow. Then method fetches existed value for provided user and from
   * internal object take object's property by provided key from local storage.
   *
   * @param key to fetch value
   */
  public override getUserItem(key: LocalItems): string {
    try {
      if (!this.getUserId()) {
        // If user not added we try to fetch key from local storage anyway to support unexpected class initialization
        // or method usage without constructing class.
        LOG.debug(`No local storage ${key} item for ${this.getUserId()} user.`);
        return SharedStorage.getItem(key);
      }

      const userStore = SharedStorage.getItem(this.getUserId());

      return JSON.parse(userStore)[key];
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown in get ${key} item for ${this.getUserId()} user.`);
    }
  }

  /**
   * Sets item to defined storage by user specific identifier.
   * <p/>
   * In order to set user specific item we need to build key/value pair itself and put it into user unique object,
   * but if user already selected any other property/properties we want to fetch them first create new object
   * and unify existed properties with new one. Any overlapping should be overwritten with new value that's why
   * {@code [key]: value} goes after distracting existed object properties. We use NX local storage library as
   * a part of shared storage in order to avoid bubbling exceptions up to browser context.
   *
   * @param key to set value
   * @param value for setting in local storage
   */
  public override setUserItem(key: LocalItems, value: string): void {
    try {
      const userStorage = SharedStorage.getItem(this.getUserId());
      if (userStorage) {
        SharedStorage.setItem(
          this.getUserId(),
          JSON.stringify({
            ...JSON.parse(userStorage),
            [key]: value
          })
        );
      } else {
        SharedStorage.setItem(
          this.getUserId(),
          JSON.stringify({
            [key]: value
          })
        );
      }
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown in get ${key} item with ${value} value for ${this.getUserId()} user.`);
    }
  }

  /**
   * Clears local storage but before clearing function fetches existed user items and after clearing all existed
   * items saves user related item back to storage.
   * */
  public override clear() {
    try {
      const userStorage = SharedStorage.getItem(this.getUserId());
      const userId = this.getUserId();
      localStorage.clear();

      if (userStorage) {
        SharedStorage.setItem(userId, userStorage);
      }
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown clearing local storage for ${this.getUserId()} user.`);
    }
  }
}
