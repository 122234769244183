import React, { useState } from 'react';
import { Modal } from '@cvent/carina/components/Modal';
import { Button } from '@cvent/carina/components/Button';
import { ScrollViewWithBars, DismissButton } from '@cvent/carina/components/ScrollViewWithBars';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import { useTranslate } from 'nucleus-text';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { NavigationActionBuilder } from '@components/topNavMenu/NavigationActionBuilder';
import { GMLQueueHotel } from '@components/topNavMenu/gml/GMLQueueHotel';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import { GMLQueueOrg } from '@components/topNavMenu/gml/GMLQueueOrg';
import LoadingSpinner from '@cvent/carina/components/LoadingSpinner';

/**
 * GML queue modal props has to get initial open state from data provider.
 */
export interface GMLQueueModalProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  hotels: Array<GMLEnabledHotel>;
  orgs: Array<GMLEnabledOrg>;
  isLoading: boolean;
  isPasskeyUser?: boolean;
}

/**
 * GML enabled hotel set fields is a set of extracted fields from types hotel to reduce impact on modal required content.
 */
export interface GMLEnabledHotel {
  hotelId: number;
  name: string;
  city: string;
  state: string;
}

/**
 * GML enabled org set fields is a set of extracted fields from org to reduce impact on modal required content.
 */
export interface GMLEnabledOrg {
  id: number;
  name: string;
}

export interface SelectedTile {
  id: number;
  type: TileType;
}

export enum TileType {
  HOTEL,
  ORG
}

const initialUnselectedTile: SelectedTile = {
  id: undefined,
  type: undefined
};

/**
 * GML queue modal that includes header with titles, footer with button group cancel and continue to navigation
 * to hotel page and collection of hotels/orgs with a card.
 * <p/>
 * Navigation action on selected hotel/org will push user to GML queue page with pre-selected PENDING in the list.
 *
 * @param props with initial state management field and list of hotels/orgs to render in a card
 */
export const GMLQueueModal = (props: GMLQueueModalProps): JSX.Element => {
  const { font } = useTheme();
  const [selectedTile, setSelectedTile] = useState<SelectedTile>(initialUnselectedTile);
  const { translate } = useTranslate();

  const isSelected = (id: number): boolean => {
    return selectedTile.id === id;
  };

  return (
    <React.Fragment>
      <Modal testID="gml-queue-modal" isOpen={props.isOpen} onDismiss={() => props.setIsOpen(false)} zIndex={201}>
        <ScrollViewWithBars
          forceStickyFooter
          header={
            <Row {...injectTestId('gml-queue-modal-header')} margin={{ top: 16, bottom: 16, start: 8, end: 8 }}>
              <Col width="fill" flex={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                <div
                  {...injectTestId('gml-queue-modal-list-header-title')}
                  css={{
                    fontSize: font.base.size.l,
                    fontWeight: font.base.weight.regular,
                    fontFamily: font.base.family,
                    color: font.color.hard,
                    lineHeight: font.lineHeight.base
                  }}
                >
                  {translate('Lighthouse_Navigation_GMLQueue_Modal_Header_Title_resx')}
                </div>
                <div
                  {...injectTestId('gml-queue-modal-list-sub-header-title')}
                  css={{
                    fontSize: font.base.size.m,
                    fontWeight: font.base.weight.regular,
                    fontFamily: font.base.family,
                    color: font.color.hard,
                    lineHeight: font.lineHeight.base
                  }}
                >
                  {translate('Lighthouse_Navigation_GMLQueue_Modal_Header_SubTitle_resx')}
                </div>
              </Col>
              <Col width="content">
                <DismissButton
                  testID="gml-queue-modal-list-dismiss-button"
                  aria-label="Dismiss"
                  onClick={() => props.setIsOpen(false)}
                />
              </Col>
            </Row>
          }
          footer={
            <Row
              testID="gml-queue-modal-button-group"
              margin={{ top: 16, bottom: 16, start: 8, end: 8 }}
              justifyContent="flex-end"
            >
              <Col width="content">
                <Button
                  testID="gml-queue-modal-list-cancel-button"
                  appearance="lined"
                  text={translate('Lighthouse_Navigation_GMLQueue_Modal_Footer_CancelButton_resx')}
                  onClick={() => props.setIsOpen(false)}
                />
              </Col>
              <Col width="content">
                <Button
                  testID="gml-queue-modal-continue-button"
                  appearance="filled"
                  disabled={!selectedTile.id}
                  autoFocus
                  text={translate('Lighthouse_Navigation_GMLQueue_Modal_Footer_ContinueButton_resx')}
                  onClick={() =>
                    // GML Queue Modal redirect to Resdesk application because the GML page doesn't exist yet.
                    (window.location.href = NavigationActionBuilder.getGMLQueueContinueLink(selectedTile))
                  }
                />
              </Col>
            </Row>
          }
        >
          {props.isLoading ? (
            <LoadingSpinner size="m" {...injectTestId('gml-queue-modal-loading-spinner')} />
          ) : (
            <Row testID="gml-queue-modal-list" margin={{ top: 16, bottom: 16, start: 8, end: 8 }}>
              {props.hotels.length > 0 || props.orgs.length > 0 ? (
                <Col width="fill">
                  {props.hotels.map(hotel => (
                    <GMLQueueHotel
                      key={hotel.hotelId}
                      hotel={hotel}
                      isSelected={isSelected(hotel.hotelId)}
                      setSelectedTile={setSelectedTile}
                    />
                  ))}
                  {props.orgs.map(org => (
                    <GMLQueueOrg
                      isPasskeyUser={props.isPasskeyUser}
                      key={org.id}
                      org={org}
                      isSelected={isSelected(org.id)}
                      setSelectedTile={setSelectedTile}
                    />
                  ))}
                </Col>
              ) : (
                <div
                  css={{
                    fontSize: font.base.size.m,
                    fontWeight: font.base.weight.regular,
                    fontFamily: font.base.family,
                    color: font.color.hard,
                    lineHeight: font.lineHeight.base,
                    margin: 0,
                    padding: 16
                  }}
                  {...injectTestId('gml-queue-modal-fallback-message-spinner')}
                  aria-label={translate('Lighthouse_Navigation_GMLQueue_Modal_Fallback_Empty_Message_resx')}
                >
                  {translate('Lighthouse_Navigation_GMLQueue_Modal_Fallback_Empty_Message_resx')}
                </div>
              )}
            </Row>
          )}
        </ScrollViewWithBars>
      </Modal>
    </React.Fragment>
  );
};
