import { MetadataFields } from '@components/types';
import { Logger } from '@cvent/nucleus-logging';
import { SharedStorage } from './SharedStorage';
import { SessionItems, StorageInitParams } from './types';

const LOG = new Logger('SessionStorage');

/**
 * Custom handler of user related selection in browser session storage.
 * <p/>
 * ParameterizedSessionStorage has singleton factory method that checks for existed instance of class and returns
 * existed class instance with saved user ID, if not creates one with provided user ID or fetches it from local storage.
 * RDK2 has use cases where we need to set session storage items with unique key/value pair. For example,
 * if several users log in from the same browser suring the same browser tab session we want to keep their saved
 * preferences separately. That is a main reason to use custom local storage. In case if we need to use any
 * value from browser storage, take a look at to methods in SharedStorage abstract class:
 * {@code SharedStorage.getItem(key: string) and SharedStorage.setItem(key: string, value: string) }
 */
export class ParameterizedSessionStorage extends SharedStorage {
  private static session: ParameterizedSessionStorage;

  private constructor(storageParams: StorageInitParams) {
    super(storageParams.userId);
  }

  /**
   * Factory static method that builds instance of ParameterizedSessionStorage class with default userID.
   *
   * @param storageParams with logged in userId
   */
  public static getSessionStorage(storageParams?: StorageInitParams): ParameterizedSessionStorage {
    if (!ParameterizedSessionStorage.session) {
      if (storageParams?.userId) {
        ParameterizedSessionStorage.session = new ParameterizedSessionStorage({ userId: storageParams?.userId });
      } else {
        LOG.debug('Set up default user id from existed local storage.');
        ParameterizedSessionStorage.session = new ParameterizedSessionStorage({
          userId: super.getItem(MetadataFields.USER_ID)
        });
      }
    }
    return ParameterizedSessionStorage.session;
  }

  /**
   * Returns item from session storage by user specific identifier.
   * <p/>
   * In order to return user specific setting we need to check if userID exists otherwise we can get exception
   * and skip an unexpected key/value saved from one page to another, even thought we shouldn't try to fetch user
   * specific settings for generic fetch but in case one-page sets item without any identifier and second page needs
   * to fetch it we still want to support flow. Then method fetches existed value for provided user and from
   * internal object take object's property by provided key from session storage.
   *
   * @param key to fetch value
   */
  public override getUserItem(key: SessionItems): string {
    try {
      if (!this.getUserId()) {
        // If user not added we try to fetch key from local storage anyway to support unexpected class initialization
        // or method usage without constructing class.
        LOG.debug(`No session storage ${key} item for ${this.getUserId()} user.`);
        return sessionStorage.getItem(key);
      }

      const userStore = sessionStorage.getItem(this.getUserId());

      return JSON.parse(userStore)[key];
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown in get ${key} item for ${this.getUserId()} user.`);
    }
  }

  /**
   * Sets item to defined storage by user specific identifier.
   * <p/>
   * In order to set user specific item we need to build key/value pair itself and put it into user unique object,
   * but if user already selected any other property/properties we want to fetch them first create new object
   * and unify existed properties with new one. Any overlapping should be overwritten with new value that's why
   * {@code [key]: value} goes after distracting existed object properties. We can't reuse NX local storage library because
   * by default they use local storage, we can change that API, and implement our session storage set item.
   *
   * @param key to set value
   * @param value for setting in session storage
   */
  public override setUserItem(key: SessionItems, value: string): void {
    try {
      const userStorage = sessionStorage.getItem(this.getUserId());
      if (userStorage) {
        sessionStorage.setItem(
          this.getUserId(),
          JSON.stringify({
            ...JSON.parse(userStorage),
            [key]: value
          })
        );
      } else {
        sessionStorage.setItem(
          this.getUserId(),
          JSON.stringify({
            [key]: value
          })
        );
      }
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown in get ${key} item with ${value} value for ${this.getUserId()} user.`);
    }
  }

  /**
   * Clears session storage but before clearing function fetches existed user items and after clearing all existed
   * items saves user related item back to storage.
   */
  public override clear() {
    try {
      const userStorage = sessionStorage.getItem(this.getUserId());
      const userId = this.getUserId();

      sessionStorage.clear();

      if (userStorage) {
        sessionStorage.setItem(userId, userStorage);
      }
    } catch (e) {
      // We don't want this exception to bubble up as it isn't critical functionality.
      LOG.debug(`Error has been thrown clearing session storage for ${this.getUserId()} user.`);
    }
  }
}
